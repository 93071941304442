<template>
	<div class="wrap">
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增充值卡</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="card_name" label="充值卡名称" width="200"></el-table-column>
			<el-table-column prop="recharge_money" label="充值金额" width="300">
			</el-table-column>
			<el-table-column prop="give" label="赠送金额" width="150">
			</el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<div v-if="scope.row.status == 1" class="text-green">正常</div>
					<div v-if="scope.row.status == 0" class="text-gray">停用</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
					<el-button type="danger" size="mini" @click="setStatus(scope.row)" v-if="scope.row.status == 1">停用</el-button>
					<el-button type="success" size="mini" @click="setStatus(scope.row)" v-else>启用</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(item) {
				if (item) {
					// 编辑
					this.$router.push(`/card/recharge/edit/${item.id}`);
				} else {
					// 新增
					this.$router.push("/card/recharge/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.card.getRecharges({
					page: 1,
					page_size: 999
				}).then(res => {
					this.loading = false;
					this.tableData = res.data.data;
				});
			},
			setStatus(item) {
				// let status = item.status;
				const loading = this.$loading()
				this.$api.card.setRechargeStatus({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						item.status = item.status == 1 ? 0 : 1;
						this.$message.success(`操作成功`);
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close();
					this.$alert(err);
				})
			}
		}
	};
</script>
<style scoped lang="scss">

</style>
